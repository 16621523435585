@keyframes moveToLeft {
  from { }
  to {
    transform: translateX(-100%); } }

@keyframes moveFromLeft {
  from {
    transform: translateX(-100%); } }

@keyframes moveToRight {
  from { }
  to {
    transform: translateX(100%); } }

@keyframes moveFromRight {
  from {
    transform: translateX(100%); } }

@keyframes moveToTop {
  from { }
  to {
    transform: translateY(-100%); } }

@keyframes moveFromTop {
  from {
    transform: translateY(-100%); } }

@keyframes moveToBottom {
  from { }
  to {
    transform: translateY(100%); } }

@keyframes moveFromBottom {
  from {
    transform: translateY(100%); } }

@keyframes fade {
  from { }
  to {
    opacity: 0.3; } }

@keyframes moveToLeftFade {
  from { }
  to {
    opacity: 0.3;
    transform: translateX(-100%); } }

@keyframes moveFromLeftFade {
  from {
    opacity: 0.3;
    transform: translateX(-100%); } }

@keyframes moveToRightFade {
  from { }
  to {
    opacity: 0.3;
    transform: translateX(100%); } }

@keyframes moveFromRightFade {
  from {
    opacity: 0.3;
    transform: translateX(100%); } }

@keyframes moveToTopFade {
  from { }
  to {
    opacity: 0.3;
    transform: translateY(-100%); } }

@keyframes moveFromTopFade {
  from {
    opacity: 0.3;
    transform: translateY(-100%); } }

@keyframes moveToBottomFade {
  from { }
  to {
    opacity: 0.3;
    transform: translateY(100%); } }

@keyframes moveFromBottomFade {
  from {
    opacity: 0.3;
    transform: translateY(100%); } }

@keyframes scaleDown {
  from { }
  to {
    opacity: 0;
    transform: scale(0.8); } }

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0.8); } }

@keyframes scaleUpDown {
  from {
    opacity: 0;
    transform: scale(1.2); } }

@keyframes scaleDownUp {
  from { }
  to {
    opacity: 0;
    transform: scale(1.2); } }

@keyframes scaleDownCenter {
  from { }
  to {
    opacity: 0;
    transform: scale(0.7); } }

@keyframes scaleUpCenter {
  from {
    opacity: 0;
    transform: scale(0.7); } }

@keyframes rotateRightSideFirst {
  0% { }
  40% {
    transform: rotateY(15deg);
    opacity: 0.8;
    animation-timing-function: ease-out; }
  100% {
    transform: scale(0.8) translateZ(-200px);
    opacity: 0; } }

@keyframes rotateLeftSideFirst {
  0% { }
  40% {
    transform: rotateY(-15deg);
    opacity: 0.8;
    animation-timing-function: ease-out; }
  100% {
    transform: scale(0.8) translateZ(-200px);
    opacity: 0; } }

@keyframes rotateTopSideFirst {
  0% { }
  40% {
    transform: rotateX(15deg);
    opacity: 0.8;
    animation-timing-function: ease-out; }
  100% {
    transform: scale(0.8) translateZ(-200px);
    opacity: 0; } }

@keyframes rotateBottomSideFirst {
  0% { }
  40% {
    transform: rotateX(-15deg);
    opacity: 0.8;
    animation-timing-function: ease-out; }
  100% {
    transform: scale(0.8) translateZ(-200px);
    opacity: 0; } }

@keyframes flipOutRight {
  from { }
  to {
    transform: translateZ(-1000px) rotateY(90deg);
    opacity: 0.2; } }

@keyframes flipInLeft {
  from {
    transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2; } }

@keyframes flipOutLeft {
  from { }
  to {
    transform: translateZ(-1000px) rotateY(-90deg);
    opacity: 0.2; } }

@keyframes flipInRight {
  from {
    transform: translateZ(-1000px) rotateY(90deg);
    opacity: 0.2; } }

@keyframes flipOutTop {
  from { }
  to {
    transform: translateZ(-1000px) rotateX(90deg);
    opacity: 0.2; } }

@keyframes flipInBottom {
  from {
    transform: translateZ(-1000px) rotateX(-90deg);
    opacity: 0.2; } }

@keyframes flipOutBottom {
  from { }
  to {
    transform: translateZ(-1000px) rotateX(-90deg);
    opacity: 0.2; } }

@keyframes flipInTop {
  from {
    transform: translateZ(-1000px) rotateX(90deg);
    opacity: 0.2; } }

@keyframes rotateFall {
  0% {
    transform: rotateZ(0deg); }
  20% {
    transform: rotateZ(10deg);
    animation-timing-function: ease-out; }
  40% {
    transform: rotateZ(17deg); }
  60% {
    transform: rotateZ(16deg); }
  100% {
    transform: translateY(100%) rotateZ(17deg); } }

@keyframes rotateOutNewspaper {
  from { }
  to {
    transform: translateZ(-3000px) rotateZ(360deg);
    opacity: 0; } }

@keyframes rotateInNewspaper {
  from {
    transform: translateZ(-3000px) rotateZ(-360deg);
    opacity: 0; } }

@keyframes rotatePushLeft {
  from { }
  to {
    opacity: 0;
    transform: rotateY(90deg); } }

@keyframes rotatePushRight {
  from { }
  to {
    opacity: 0;
    transform: rotateY(-90deg); } }

@keyframes rotatePushTop {
  from { }
  to {
    opacity: 0;
    transform: rotateX(-90deg); } }

@keyframes rotatePushBottom {
  from { }
  to {
    opacity: 0;
    transform: rotateX(90deg); } }

@keyframes rotatePullRight {
  from {
    opacity: 0;
    transform: rotateY(-90deg); } }

@keyframes rotatePullLeft {
  from {
    opacity: 0;
    transform: rotateY(90deg); } }

@keyframes rotatePullTop {
  from {
    opacity: 0;
    transform: rotateX(-90deg); } }

@keyframes rotatePullBottom {
  from {
    opacity: 0;
    transform: rotateX(90deg); } }

@keyframes rotateFoldRight {
  from { }
  to {
    opacity: 0;
    transform: translateX(100%) rotateY(90deg); } }

@keyframes rotateFoldLeft {
  from { }
  to {
    opacity: 0;
    transform: translateX(-100%) rotateY(-90deg); } }

@keyframes rotateFoldTop {
  from { }
  to {
    opacity: 0;
    transform: translateY(-100%) rotateX(90deg); } }

@keyframes rotateFoldBottom {
  from { }
  to {
    opacity: 0;
    transform: translateY(100%) rotateX(-90deg); } }

@keyframes rotateUnfoldLeft {
  from {
    opacity: 0;
    transform: translateX(-100%) rotateY(-90deg); } }

@keyframes rotateUnfoldRight {
  from {
    opacity: 0;
    transform: translateX(100%) rotateY(90deg); } }

@keyframes rotateUnfoldTop {
  from {
    opacity: 0;
    transform: translateY(-100%) rotateX(90deg); } }

@keyframes rotateUnfoldBottom {
  from {
    opacity: 0;
    transform: translateY(100%) rotateX(-90deg); } }

@keyframes rotateRoomLeftOut {
  from { }
  to {
    opacity: 0.3;
    transform: translateX(-100%) rotateY(90deg); } }

@keyframes rotateRoomLeftIn {
  from {
    opacity: 0.3;
    transform: translateX(100%) rotateY(-90deg); } }

@keyframes rotateRoomRightOut {
  from { }
  to {
    opacity: 0.3;
    transform: translateX(100%) rotateY(-90deg); } }

@keyframes rotateRoomRightIn {
  from {
    opacity: 0.3;
    transform: translateX(-100%) rotateY(90deg); } }

@keyframes rotateRoomTopOut {
  from { }
  to {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(-90deg); } }

@keyframes rotateRoomTopIn {
  from {
    opacity: 0.3;
    transform: translateY(100%) rotateX(90deg); } }

@keyframes rotateRoomBottomOut {
  from { }
  to {
    opacity: 0.3;
    transform: translateY(100%) rotateX(90deg); } }

@keyframes rotateRoomBottomIn {
  from {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(-90deg); } }

@keyframes rotateCubeLeftOut {
  0% { }
  50% {
    animation-timing-function: ease-out;
    transform: translateX(-50%) translateZ(-200px) rotateY(-45deg); }
  100% {
    opacity: 0.3;
    transform: translateX(-100%) rotateY(-90deg); } }

@keyframes rotateCubeLeftIn {
  0% {
    opacity: 0.3;
    transform: translateX(100%) rotateY(90deg); }
  50% {
    animation-timing-function: ease-out;
    transform: translateX(50%) translateZ(-200px) rotateY(45deg); } }

@keyframes rotateCubeRightOut {
  0% { }
  50% {
    animation-timing-function: ease-out;
    transform: translateX(50%) translateZ(-200px) rotateY(45deg); }
  100% {
    opacity: 0.3;
    transform: translateX(100%) rotateY(90deg); } }

@keyframes rotateCubeRightIn {
  0% {
    opacity: 0.3;
    transform: translateX(-100%) rotateY(-90deg); }
  50% {
    animation-timing-function: ease-out;
    transform: translateX(-50%) translateZ(-200px) rotateY(-45deg); } }

@keyframes rotateCubeTopOut {
  0% { }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-50%) translateZ(-200px) rotateX(45deg); }
  100% {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(90deg); } }

@keyframes rotateCubeTopIn {
  0% {
    opacity: 0.3;
    transform: translateY(100%) rotateX(-90deg); }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(50%) translateZ(-200px) rotateX(-45deg); } }

@keyframes rotateCubeBottomOut {
  0% { }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(50%) translateZ(-200px) rotateX(-45deg); }
  100% {
    opacity: 0.3;
    transform: translateY(100%) rotateX(-90deg); } }

@keyframes rotateCubeBottomIn {
  0% {
    opacity: 0.3;
    transform: translateY(-100%) rotateX(90deg); }
  50% {
    animation-timing-function: ease-out;
    transform: translateY(-50%) translateZ(-200px) rotateX(45deg); } }

@keyframes rotateCarouselLeftOut {
  from { }
  to {
    opacity: 0.3;
    transform: translateX(-150%) scale(0.4) rotateY(-65deg); } }

@keyframes rotateCarouselLeftIn {
  from {
    opacity: 0.3;
    transform: translateX(200%) scale(0.4) rotateY(65deg); } }

@keyframes rotateCarouselRightOut {
  from { }
  to {
    opacity: 0.3;
    transform: translateX(200%) scale(0.4) rotateY(65deg); } }

@keyframes rotateCarouselRightIn {
  from {
    opacity: 0.3;
    transform: translateX(-200%) scale(0.4) rotateY(-65deg); } }

@keyframes rotateCarouselTopOut {
  from { }
  to {
    opacity: 0.3;
    transform: translateY(-200%) scale(0.4) rotateX(65deg); } }

@keyframes rotateCarouselTopIn {
  from {
    opacity: 0.3;
    transform: translateY(200%) scale(0.4) rotateX(-65deg); } }

@keyframes rotateCarouselBottomOut {
  from { }
  to {
    opacity: 0.3;
    transform: translateY(200%) scale(0.4) rotateX(-65deg); } }

@keyframes rotateCarouselBottomIn {
  from {
    opacity: 0.3;
    transform: translateY(-200%) scale(0.4) rotateX(65deg); } }

/* sides */
@keyframes rotateSidesOut {
  from { }
  to {
    opacity: 0;
    transform: translateZ(-500px) rotateY(90deg); } }

@keyframes rotateSidesIn {
  from {
    opacity: 0;
    transform: translateZ(-500px) rotateY(-90deg); } }

@keyframes rotateSlideOut {
  0% { }
  25% {
    opacity: 0.5;
    transform: translateZ(-500px); }
  75% {
    opacity: 0.5;
    transform: translateZ(-500px) translateX(-200%); }
  100% {
    opacity: 0.5;
    transform: translateZ(-500px) translateX(-200%); } }

@keyframes rotateSlideIn {
  0%,
  25% {
    opacity: 0.5;
    transform: translateZ(-500px) translateX(200%); }
  75% {
    opacity: 0.5;
    transform: translateZ(-500px); }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0); } }

.Admin {
  height: 100%;
  min-height: 100%; }
  .Admin .Admin__inner,
  .Admin .Admin__navigation {
    height: 100%; }
  .Admin__inner {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-height: 100%; }
  .Admin__areabackground {
    height: 250px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0; }
  .Admin__padding-top {
    margin-top: 60px; }
  .Admin__header {
    z-index: 100;
    height: 104px; }
    .Admin__header .AppHeader {
      padding: 32px 24px 24px; }
  .Admin__extra-height {
    height: 196px; }
  .Admin__navigation {
    padding-top: 124px;
    position: absolute;
    width: 256px;
    z-index: 100; }
  .Admin__content {
    margin-left: 256px;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    z-index: 100; }
  .Admin__transition-group {
    bottom: 0;
    height: 100%;
    left: 0;
    perspective: 1200px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
    .Admin__transition-group .transition-codrops-scale-down-from-right {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
      overflow: hidden;
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
      transform-style: preserve-3d; }
      .Admin__transition-group .transition-codrops-scale-down-from-right-enter-active {
        animation: moveFromRight 0.6s ease both;
        z-index: 9999; }
      .Admin__transition-group .transition-codrops-scale-down-from-right-exit-active {
        animation: scaleDown 0.7s ease both; }
