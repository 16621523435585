.typography-display4 {
  color: #0c0c0c;
  font-size: 112px;
  font-weight: 300; }
  @media screen and (max-width: 600px) {
    .typography-display4 {
      font-size: 112px; } }

.typography-display3 {
  color: #0c0c0c;
  font-size: 56px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-display3 {
      font-size: 56px; } }

.typography-display2 {
  color: #0c0c0c;
  font-size: 45px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-display2 {
      font-size: 45px; } }

.typography-display1 {
  color: #0c0c0c;
  font-size: 34px;
  font-weight: 500; }
  @media screen and (max-width: 600px) {
    .typography-display1 {
      font-size: 34px; } }

.typography-header {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-header {
      font-size: 24px; } }

.typography-subheading {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-subheading {
      font-size: 18px; } }

.typography-title {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-title {
      font-size: 24px; } }

.typography-subtitle {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-subtitle {
      font-size: 16px; } }

.typography-body1 {
  color: #0c0c0c;
  font-size: 15px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-body1 {
      font-size: 15px; } }

.typography-body2 {
  color: #0c0c0c;
  font-size: 13px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-body2 {
      font-size: 13px; } }

.typography-button {
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-transform: none; }
  @media screen and (max-width: 600px) {
    .typography-button {
      font-size: 14px; } }

.typography-button--light {
  font-weight: 400; }

.typography-caption {
  color: #0c0c0c;
  font-size: 12px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-caption {
      font-size: 12px; } }

.PageList__header {
  height: 70px; }
  .PageList__header-title {
    color: #0c0c0c;
    font-size: 24px;
    font-weight: 400;
    margin: 0; }
    @media screen and (max-width: 600px) {
      .PageList__header-title {
        font-size: 24px; } }

.StripeTOS__header {
  height: 70px; }
  .StripeTOS__header-title {
    color: #0c0c0c;
    font-size: 24px;
    font-weight: 400;
    margin: 0; }
    @media screen and (max-width: 600px) {
      .StripeTOS__header-title {
        font-size: 24px; } }
