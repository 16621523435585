.typography-display4 {
  color: #0c0c0c;
  font-size: 112px;
  font-weight: 300; }
  @media screen and (max-width: 600px) {
    .typography-display4 {
      font-size: 112px; } }

.typography-display3 {
  color: #0c0c0c;
  font-size: 56px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-display3 {
      font-size: 56px; } }

.typography-display2 {
  color: #0c0c0c;
  font-size: 45px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-display2 {
      font-size: 45px; } }

.typography-display1 {
  color: #0c0c0c;
  font-size: 34px;
  font-weight: 500; }
  @media screen and (max-width: 600px) {
    .typography-display1 {
      font-size: 34px; } }

.typography-header {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-header {
      font-size: 24px; } }

.typography-subheading {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-subheading {
      font-size: 18px; } }

.typography-title {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-title {
      font-size: 24px; } }

.typography-subtitle {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-subtitle {
      font-size: 16px; } }

.typography-body1 {
  color: #0c0c0c;
  font-size: 15px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-body1 {
      font-size: 15px; } }

.typography-body2 {
  color: #0c0c0c;
  font-size: 13px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-body2 {
      font-size: 13px; } }

.typography-button {
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-transform: none; }
  @media screen and (max-width: 600px) {
    .typography-button {
      font-size: 14px; } }

.typography-button--light {
  font-weight: 400; }

.typography-caption {
  color: #0c0c0c;
  font-size: 12px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-caption {
      font-size: 12px; } }

.LogInPage {
  background-color: #fafafa;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }
  .LogInPage__card {
    padding: 48px;
    overflow: hidden; }
    @media screen and (max-width: 600px) {
      .LogInPage__card {
        bottom: 16px;
        left: 16px;
        position: absolute;
        right: 16px;
        top: 16px;
        padding: 24px; } }
  .LogInPage__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px; }
  .LogInPage__logo {
    width: auto; }
    @media screen and (max-width: 600px) {
      .LogInPage__logo {
        max-width: 330px; } }
  .LogInPage__title {
    font-weight: 300;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-bottom: 16px; }
    @media screen and (max-width: 600px) {
      .LogInPage__title {
        font-weight: 400; } }
  .LogInPage__subheading {
    color: #0c0c0c;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
    margin-top: 4px;
    margin-bottom: 4px;
    padding-bottom: 16px; }
    @media screen and (max-width: 600px) {
      .LogInPage__subheading {
        font-size: 18px; } }
  .LogInPage__main {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 280px; }
    .LogInPage__main .cardSlide-enter-active {
      animation-duration: 220ms;
      animation-fill-mode: both;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-name: slide-in; }

@keyframes slide-in {
  0% {
    transform: translateX(448px); }
  100% {
    transform: translateX(0); } }
    .LogInPage__main .cardSlide-exit-active {
      animation-duration: 220ms;
      animation-fill-mode: both;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-name: slide-out; }

@keyframes slide-out {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-448px); } }
    .LogInPage__main > div {
      width: 100%; }
    @media screen and (max-width: 600px) {
      .LogInPage__main {
        height: 100%;
        width: 100%; } }
