.SingleDatePickerInput {
  background-color: transparent; }
  .SingleDatePickerInput_calendarIcon {
    margin-left: -8px; }
    .SingleDatePickerInput_calendarIcon:focus {
      outline: 0; }
  .SingleDatePickerInput .DateInput_input {
    font-family: 'Roboto' !important;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 4px; }
    .SingleDatePickerInput .DateInput_input__focused {
      border-bottom: none; }
    .SingleDatePickerInput .DateInput_input__disabled {
      font-style: normal;
      opacity: 0.65; }
