.typography-display4 {
  color: #0c0c0c;
  font-size: 112px;
  font-weight: 300; }
  @media screen and (max-width: 600px) {
    .typography-display4 {
      font-size: 112px; } }

.typography-display3 {
  color: #0c0c0c;
  font-size: 56px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-display3 {
      font-size: 56px; } }

.typography-display2 {
  color: #0c0c0c;
  font-size: 45px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-display2 {
      font-size: 45px; } }

.typography-display1 {
  color: #0c0c0c;
  font-size: 34px;
  font-weight: 500; }
  @media screen and (max-width: 600px) {
    .typography-display1 {
      font-size: 34px; } }

.typography-header {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-header {
      font-size: 24px; } }

.typography-subheading {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-subheading {
      font-size: 18px; } }

.typography-title {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-title {
      font-size: 24px; } }

.typography-subtitle {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-subtitle {
      font-size: 16px; } }

.typography-body1 {
  color: #0c0c0c;
  font-size: 15px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-body1 {
      font-size: 15px; } }

.typography-body2 {
  color: #0c0c0c;
  font-size: 13px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-body2 {
      font-size: 13px; } }

.typography-button {
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-transform: none; }
  @media screen and (max-width: 600px) {
    .typography-button {
      font-size: 14px; } }

.typography-button--light {
  font-weight: 400; }

.typography-caption {
  color: #0c0c0c;
  font-size: 12px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-caption {
      font-size: 12px; } }

/*
 *  Used to mimic default padding on CardContent MUI element
 */
.card-content-padding {
  padding-left: 24px;
  padding-right: 24px; }

.typography-display4 {
  color: #0c0c0c;
  font-size: 112px;
  font-weight: 300; }
  @media screen and (max-width: 600px) {
    .typography-display4 {
      font-size: 112px; } }

.typography-display3 {
  color: #0c0c0c;
  font-size: 56px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-display3 {
      font-size: 56px; } }

.typography-display2 {
  color: #0c0c0c;
  font-size: 45px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-display2 {
      font-size: 45px; } }

.typography-display1 {
  color: #0c0c0c;
  font-size: 34px;
  font-weight: 500; }
  @media screen and (max-width: 600px) {
    .typography-display1 {
      font-size: 34px; } }

.typography-header {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-header {
      font-size: 24px; } }

.typography-subheading {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-subheading {
      font-size: 18px; } }

.typography-title {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-title {
      font-size: 24px; } }

.typography-subtitle {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-subtitle {
      font-size: 16px; } }

.typography-body1 {
  color: #0c0c0c;
  font-size: 15px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-body1 {
      font-size: 15px; } }

.typography-body2 {
  color: #0c0c0c;
  font-size: 13px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-body2 {
      font-size: 13px; } }

.typography-button {
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-transform: none; }
  @media screen and (max-width: 600px) {
    .typography-button {
      font-size: 14px; } }

.typography-button--light {
  font-weight: 400; }

.typography-caption {
  color: #0c0c0c;
  font-size: 12px;
  font-weight: 400; }
  @media screen and (max-width: 600px) {
    .typography-caption {
      font-size: 12px; } }

.ContractDetailsSpecifics__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px 0 16px;
  margin-bottom: 8px; }
  .ContractDetailsSpecifics__header-title {
    flex-grow: 2;
    color: #7b7b7b; }
  .ContractDetailsSpecifics__header-actions {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-end; }

.ContractDetailsSpecifics__main {
  padding: 24px; }
  .ContractDetailsSpecifics__main-title {
    color: #0c0c0c;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 16px; }
    @media screen and (max-width: 600px) {
      .ContractDetailsSpecifics__main-title {
        font-size: 24px; } }
  .ContractDetailsSpecifics__main-subtitle {
    color: #0c0c0c;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 16px; }
    @media screen and (max-width: 600px) {
      .ContractDetailsSpecifics__main-subtitle {
        font-size: 18px; } }

.ContractDetailsSpecifics__editable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

.ContractDetailsSpecifics__edit-input {
  width: 80px;
  font-size: inherit;
  font-family: inherit;
  padding: 0; }

.ContractDetailsSpecifics__main-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.ContractDetailsSpecifics__main-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.ContractDetailsSpecifics__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 64px;
  margin-bottom: 24px; }
  .ContractDetailsSpecifics__info-titles {
    color: #7b7b7b;
    white-space: nowrap;
    margin-right: 32px; }
  .ContractDetailsSpecifics__info-values {
    white-space: nowrap; }

.ContractDetailsSpecifics__downloads {
  flex-grow: 4;
  display: flex;
  flex-direction: column; }

.container {
  text-align: end; }
