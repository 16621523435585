.ReleaseVersion__paper {
  padding: 32px 0 0 32px; }

.ReleaseVersion__row {
  margin-bottom: 32px; }

.ReleaseVersion__header {
  display: flex;
  justify-content: left;
  align-items: center; }
  .ReleaseVersion__header h3 {
    font-size: 20px;
    margin: 0 0 4px 16px;
    font-weight: 700; }

.ReleaseVersion__badge {
  border-radius: 4px;
  color: #f2f3f6;
  font-weight: 600;
  text-align: center;
  margin: 0 0 4px;
  line-height: 1.2em;
  font-size: 16px;
  min-width: 80px;
  padding: 8px;
  background-color: #008b7d; }

.ReleaseNote__list {
  list-style-type: none;
  margin-top: 4px;
  margin-left: 48px; }
  .ReleaseNote__list h5 {
    font-size: 13px;
    font-weight: 400;
    margin-left: 8px;
    margin-bottom: 0; }
  .ReleaseNote__list li {
    display: flex;
    flex-direction: row;
    align-items: baseline; }

.ReleaseNote__badge {
  border-radius: 4px;
  color: #f2f3f6;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  padding: 4px;
  font-size: 12px;
  min-width: 70px; }

.ReleaseNote__ADDED {
  background-color: #0dac64; }

.ReleaseNote__FIXED {
  background-color: #f95113; }

.ReleaseNote__NEW {
  background-color: #5392c0; }

.ReleaseNote__REMOVED {
  background-color: #c03705; }

.ReleaseNote__UPDATED {
  background-color: #03854a; }

.ReleaseNote__IMPROVED {
  background-color: #0a4d7b; }
