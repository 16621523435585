.LogInPanel {
  margin: 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%; }
  .LogInPanel .cardSlide-enter-active {
    animation-duration: 220ms;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-name: slide-in; }

@keyframes slide-in {
  0% {
    transform: translateX(448px); }
  100% {
    transform: translateX(0); } }
  .LogInPanel .cardSlide-exit-active {
    animation-duration: 220ms;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-name: slide-out; }

@keyframes slide-out {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-448px); } }
  .LogInPanel__inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 600px) {
      .LogInPanel__inner {
        height: 86%; } }
  .LogInPanel__header {
    margin-bottom: 24px; }
  .LogInPanel__main {
    margin-bottom: auto; }
  .LogInPanel form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .LogInPanel__actions-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end; }
  .LogInPanel__action {
    display: flex;
    flex-direction: column-reverse; }
    .LogInPanel__action-primary {
      display: flex;
      justify-content: flex-end; }
    .LogInPanel__action-secondary {
      display: flex;
      justify-content: flex-start;
      padding-top: 1rem;
      width: 100%; }
    .LogInPanel__action-backbutton {
      margin-right: 12px !important; }

@keyframes autofill {
  to {
    background: transparent; } }
  .LogInPanel input:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: both; }
  @media screen and (max-width: 600px) {
    .LogInPanel {
      height: calc(100% - 88px); } }
