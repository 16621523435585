.CookiesPolicy__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px; }

.CookiesPolicy__table-wrapper {
  margin-bottom: 32px; }

.CookiesPolicy__small-cell {
  width: 10%; }

.CookiesPolicy__big-cell {
  width: 70%; }
